/**
 * @file VUE 过滤器扩展：truncate 函数
 * 作用：字符超长打点
 * @author qiaogang
 * @date 2019-08-26
 */
import Vue from 'vue';
import _ from '@/utils/lodash.custom.js';

/**
 * 字符串截取过滤器，其中 options 参数请参考 lodash 的 truncate 函数
 * @param {string} str
 * @param {options} options
 * @options {number=30} options.length: The maximum string length.
 * @options {string='...'} options.omission: The string to indicate text is omitted.
 * @options {RegExp|string} options.separator: The separator pattern to truncate to.
 */
Vue.filter('truncate', (str, options) => {
  if (typeof str === 'string') {
    const truncatedStr = _.truncate(str, options);
    return truncatedStr;
  }
});
