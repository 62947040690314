import Vue from 'vue';

import MessageBox from './MessageBox/index';

Vue.use(MessageBox);

import OLogin from './OperationAcitvityLogin/index';

Vue.use(OLogin);

import CoolMobileUI from '@igetcool/cool-mobile-ui';

Vue.use(CoolMobileUI);
