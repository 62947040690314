import Vue from 'vue';

/**
 * 字符串截取过滤器，其中 options 参数请参考 lodash 的 truncate 函数
 * @param {string} str
 * @param {options} options
 * @options {number=30} options.length: The maximum string length.
 * @options {string='...'} options.omission: The string to indicate text is omitted.
 * @options {RegExp|string} options.separator: The separator pattern to truncate to.
 */

function number2Str(num) {
  num = parseInt(num);
  return num < 10 ? '0' + num : '' + num;
}

Vue.filter('getChineseYMDStage', (timeStr, type) => {
  let date = new Date(timeStr);
  let year = date.getFullYear();
  let month = number2Str(date.getMonth() + 1);
  let day = number2Str(date.getDate());
  let result = '';
  switch (type) {
    case 'YM':
      result = year + '年' + month + '月';
      break;
    case 'MD':
      result = month + '月' + day + '日';
      break;
    case 'YMD':
      result = year + '年' + month + '月' + day + '日';
      break;
    default:
      break;
  }
  return result;
});
