/**
 * @file 统一加载公用模块
 * @author qiaogang
 * @date 2019-08-26
 */
import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import { fixed } from '@/utils/directives';


import './extensions/index';

// 注册模板
import './components/index.js';
import { Log, httpRequest, logTimeTrack, LogTrack } from './utils';

if (process.env.VUE_APP_ENVIRONMENT !== 'PRODUCTION') {
  let url = new URLSearchParams(location.search);
  let debugLog = !!url.get('debug_log');

  LogTrack.config({
    debug: debugLog,
    dedaoLog: true,
    sensorsLog: true,
  }).init();
  Log.config({
    debug: true
  });
} else {
  LogTrack.init();
}

Vue.prototype.$timeTrack = logTimeTrack;
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

Vue.directive('fixed', fixed);

function _stringifyPostData(config) {
  const { headers = {}, method, data = {} } = config;
  if (method === 'post') {
    const contentType = (headers['Content-Type'] || '').toLowerCase();
    if (contentType.indexOf('application/json') === -1) {
      config.data = qs.stringify(data);
    }
  }
  return config;
}

axios.interceptors.request.use(config => {
  config = _stringifyPostData(config);
  return config;
});

httpRequest.config({
  init: (opts) => {
    const { axios: _axios } = opts;
    _axios.interceptors.request.use(config => {
      config = _stringifyPostData(config);
      return config;
    });
  }
});
