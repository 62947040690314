<template>
  <div class="message-box-container" v-if="isShow" @touchmove.prevent.self @click.prevent.self>
    <div class="message-box-section">
      <div class="close-btn">
        <span class="close-img" @click.stop.self="closeMessageBox" v-log:click="{...log, name:'关闭升级'}"></span>
      </div>
      <div class="upgrade-version-word">{{messageWords}}</div>
      <div class="upgrade-version-btn" @click="buttonAction" v-log:click="{...log, name:'升级版本'}">{{buttonWords}}</div>
    </div>
  </div>
</template>


<script>
import { compareVersion, env } from '../../utils';
import {
  toUpgradeVersion
} from '../../utils/app';
export default {
  props: {
    messageWords: {
      type: String,
      default: '升级最新版本，即可立即参与此活动'
    },
    buttonWords: {
      type: String,
      default: '立即升级'
    },
    hasAction: false,
    log: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      isShow: false,
      resolve: '',
      promise: ''
    };
  },
  methods: {
    // 弹出messageBox,并创建promise对象
    showMsgBox: function() {
      this.isShow = true;
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
      });
      return this.promise;
    },
    remove: function() {
      setTimeout(() => {
        this.destroy();
      }, 300);
    },
    destroy: function() {
      this.$destroy();
      document.body.removeChild(this.$el);
    },
    closeMessageBox() {
      this.isShow = false;
      this.remove();
    },
    // 确定，将promise断定为resolve状态
    buttonAction() {
      this.isShow = false;
      if (this.hasAction) {
        this.resolve();
      } else {
        toUpgradeVersion();
      }
      this.remove();
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}

.message-box-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  .message-box-section {
    font-size: 0.16rem;
    position: absolute;
    width: 80%;
    left: 10%;
    right: 10%;
    background-color: #ffffff;
    border-radius: 0.1rem;
    text-align: center;
    .close-btn {
      color: #a0a7b6;
      text-align: right;
      font-size: 0;
    }
    .upgrade-version-word {
      color: #2c3b53;
      padding: 0 5%;
    }
    .upgrade-version-btn {
      width: 80%;
      background-color: #ff7349;
      box-shadow: 0px 6px 10px 0px rgba(255, 115, 73, 0.6);
      border-radius: 84px;
      border: 1px solid rgba(255, 255, 255, 0.6);
      color: #ffffff;
      font-size: 0.14rem;
    }
  }
}
/*
按钮：0.45
底部：0.225
文字上——按钮上:0.45
文字上——关闭按钮：0.236
关闭按钮大小：0.3375
*/
@media (max-width: 350px) {
  .message-box-container {
    .message-box-section {
      width: 80%;
      left: 10%;
      right: 10%;
      height: 1.7rem;
      top: 50%;
      margin: -0.85rem 0 0 0;
      .close-btn {
        span {
          display: inline-block;
          background: url("../../assets/img/activity/close_message.png") no-repeat;
          background-size: contain;
          width: 0.3rem;
          height: 0.3rem;
        }
      }
      .upgrade-version-word {
        height: 0.4rem;
        margin-top: 0.1rem;
        margin-bottom: 0.3rem;
      }
      .upgrade-version-btn {
        margin: 0 auto;
        margin-bottom: 0.2rem;
        height: 0.4rem;
        line-height: 0.4rem;
      }
    }
  }
}
/*
按钮：0.45
底部：0.225
文字上——按钮上:0.45
文字上——关闭按钮：0.236
关闭按钮大小：0.3375
*/
@media screen and (min-width: 350px) and (max-width: 700px) {
  .message-box-container {
    .message-box-section {
      width: 80%;
      height: 1.9rem;
      top: 50%;
      margin: -0.95rem 0 0 0;
      .close-btn {
        span {
          display: inline-block;
          background: url("../../assets/img/activity/close_message.png") no-repeat;
          background-size: contain;
          width: 0.35rem;
          height: 0.35rem;
        }
      }
      .upgrade-version-word {
        // height: 0.4rem;
        // margin-top: 0.1rem;
        // margin-bottom: 0.25rem;
        height: 0.45rem;
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
      }
      .upgrade-version-btn {
        margin: 0 auto;
        margin-bottom: 0.25rem;
        height: 0.45rem;
        line-height: 0.45rem;
      }
    }
  }
}
/*
按钮：0.4
底部：0.2
文字上——按钮上:0.73
文字上——关闭按钮：0.1
关闭按钮大小：0.3
*/
@media screen and (min-width: 700px) and (max-width: 1000px) {
  .message-box-container {
    .message-box-section {
      width: 70%;
      height: 1.7rem;
      top: 50%;
      margin: -0.85rem 0 0 0;
      left: 15%;
      right: 15%;
      .close-btn {
        span {
          display: inline-block;
          background: url("../../assets/img/activity/close_message@2x.png")
            no-repeat;
          background-size: contain;
          width: 0.3rem;
          height: 0.3rem;
        }
      }
      .upgrade-version-word {
        height: 0.4rem;
        margin-top: 0.1rem;
        margin-bottom: 0.3rem;
      }
      .upgrade-version-btn {
        margin: 0 auto;
        margin-bottom: 0.2rem;
        height: 0.4rem;
        line-height: 0.4rem;
      }
    }
  }
}
/*
都以按钮未参考比例：
框的百分比：320/600 = 53.333%
文字上——按钮上(101+45)
(101+45) /80 = 1.825
文字上——关闭按钮：42      0.236
42/80=0.525
按钮和关闭按钮比例：60/80=0.75
按钮下——边界下40/80= 0.5

按钮：0.45
底部：0.225
文字上——按钮上:0.45
文字上——关闭按钮：0.236
关闭按钮大小：0.3375
*/
@media (min-width: 1000px) {
  .message-box-container {
    .message-box-section {
      width: 60%;
      height: 1.9rem;
      top: 50%;
      margin: -0.95rem 0 0 0;
      left: 20%;
      right: 20%;
      .close-btn {
        span {
          display: inline-block;
          background: url("../../assets/img/activity/close_message@2x.png")
            no-repeat;
          background-size: contain;
          width: 0.35rem;
          height: 0.35rem;
        }
      }
      .upgrade-version-word {
        height: 0.45rem;
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
      }
      .upgrade-version-btn {
        margin: 0 auto;
        margin-bottom: 0.25rem;
        height: 0.45rem;
        line-height: 0.45rem;
      }
    }
  }
}
</style>
