
export default [
  {

    /**
     * 御猫扫码兑换课程
     * @author 高长浩 2020-08-10 15:12:15
     */
    path: 'exchangeCourse',
    name: 'exchangeCourse',
    component: () => import(/* webpackChunkName: 'inviteCoupon' */ '@/views/inviteCoupon/exchangeCourse/exchangeCourse.vue')
  }
];
