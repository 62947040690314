import { env } from './index.js';
import { Config } from '../config/index.js';

/**
 * 升级版本
 */
export const toUpgradeVersion = () => {
  if (env.isAndroidApp) {
    location.href = Config.ANDROID_APK;
  } else if (env.isIOSApp) {
    location.href = Config.IOS_APK;
  }
};
