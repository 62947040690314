/**
 * 基础路由
 */
import Dash from '@/views/Dash';

// 御猫扫码兑换课程
import exchangeCourse from './exchangeCourse/exchangeCourse';

const routes = [
  {
    path: '/h5Transfer',
    name: 'h5Transfer',
    component: () => import(/* webpackChunkName: 'h5Transfer' */ '@/views/h5Transfer/index.vue')
  },
  {
    path: '/h5TransferFission',
    name: 'h5TransferFission',
    component: () => import(/* webpackChunkName: 'h5TransferFission' */ '@/views/h5TransferFission/index.vue')
  },
  {
    path: '/openOtherApp',
    name: 'openOtherApp',
    component: () => import(/* webpackChunkName: 'openOtherApp' */ '@/views/openOtherApp/index.vue')
  },
  {
    path: '/goldStore/middlepage',
    name: 'goldStoreMiddlepage',
    component: () => import(/* webpackChunkName: 'middlepage' */ '@/views/goldStore/goldStoreMiddleware.vue')
  },

  // 临时需求
  {
    path: '/temporary/JS2Native',
    name: 'JS2Native',
    component: () => import(/* webpackChunkName: 'JS2Native' */ '@/views/temporary/JS2Native.vue')
  },
  {
    path: '/temporary/debug',
    name: 'debug',
    component: () => import(/* webpackChunkName: 'debug' */ '@/views/temporary/debug.vue')
  },

  // 语音测评-关注公众号
  // {
  //   path: '/temporary/taskTest/',
  //   component: Dash,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import(/* webpackChunkName: 'taskTest' */ '@/views/temporary/taskTest/index.vue')
  //     },
  //     {
  //       path: 'record',
  //       component: () => import(/* webpackChunkName: 'taskTest' */ '@/views/temporary/taskTest/record.vue')
  //     },
  //     {
  //       path: 'result',
  //       component: () => import(/* webpackChunkName: 'taskTest' */ '@/views/temporary/taskTest/result.vue')
  //     }
  //   ]
  //
  // },

  {
    path: '/book',
    name: 'book',
    component: () => import(/* webpackChunkName: 'book' */ '@/views/book/newBook.vue')
  },
  {
    path: '/newBook',
    name: 'newBook',
    component: () => import(/* webpackChunkName: 'book' */ '@/views/book/newBook.vue')
  },

  // {
  //   path: '/packageIndex',
  //   name: 'packageIndex',
  //   component: () => import(/* webpackChunkName: 'packageIndex' */ '@/views/package/detail.vue')
  // },
  {
    path: '/certificate/',
    component: Dash,
    children: [
      {
        path: 'endCourseCertificate',
        component: () => import(/* webpackChunkName: 'certificate' */ '@/views/certificate/endCourseCertificate.vue')
      }
    ]
  },
  {
    path: '/allComment',
    name: 'allComment',
    component: () => import(/* webpackChunkName: 'allComment' */ '@/views/course/allComment.vue')
  },
  {
    path: '/course',
    name: 'course',
    component: () => import(/* webpackChunkName: 'courseDetail' */ '@/views/course/newCourseDetail.vue')
  },

  // 3.2.0 改版的音频课程详情页
  {
    path: '/courseDetail',
    name: 'courseDetail',
    component: () => import(/* webpackChunkName: 'courseDetail' */ '@/views/course/newCourseDetail.vue')
  },
  // TODO: 没有访问量，应该是个老页面，可以考虑移除
  // {
  //   path: '/courseBunch',
  //   name: 'courseBunch',
  //   component: () => import(/* webpackChunkName: 'courseBunch' */ '@/views/course/courseBunch.vue')
  // },
  {
    path: '/courseVideo',
    name: 'courseVideo',
    component: () => import(/* webpackChunkName: 'courseVideo' */ '@/views/course/courseVideo.vue')
  },
  {
    path: '/courseSingleVideo',
    name: 'courseSingleVideo',
    component: () => import(/* webpackChunkName: 'courseSingleVideo' */ '@/views/course/courseSingleVideo.vue')
  },
  {
    path: '/courseMixSingleVideo',
    name: 'courseMixSingleVideo',
    component: () => import(/* webpackChunkName: 'courseMixSingleVideo' */ '@/views/course/courseMixSingleVideo.vue')
  },
  {
    path: '/courseDetailQrCode',
    name: 'courseDetailQrCode',
    component: () => import(/* webpackChunkName: 'courseDetailQrCode' */ '@/views/courseQrCode/courseDetail.vue')
  },
  {
    path: '/courseVideoQrCode',
    name: 'courseVideoQrCode',
    component: () => import(/* webpackChunkName: 'courseVideoQrCode' */ '@/views/courseQrCode/courseVideo.vue')
  },
  {
    path: '/courseSingleVideoQrCode',
    name: 'courseSingleVideoQrCode',
    component: () => import(/* webpackChunkName: 'courseSingleVideoQrCode' */ '@/views/courseQrCode/courseSingleVideo.vue')
  },
  // TODO: 非常老的页面了，虽然有流量，但是非常少，且对应的内容是很老的课程，已经不售卖了
  // {
  //   path: '/courseLive',
  //   name: 'courseLive',
  //   component: () => import(/* webpackChunkName: 'courseLive' */ '@/views/course/courseLive.vue')
  // },
  {
    path: '/coursePlan',
    name: 'coursePlan',
    component: () => import(/* webpackChunkName: 'coursePlan' */ '@/views/course/coursePlan.vue')
  },
  {
    path: '/after-reading',
    name: 'afterReading',
    component: () => import(/* webpackChunkName: 'afterReading' */ '@/views/course/afterReading.vue')
  },
  {
    path: '/gold-rule',
    name: 'goldRule',
    component: () => import(/* webpackChunkName: 'goldRule' */ '@/views/goldStore/goldRule.vue')
  },
  {
    path: '/gold-rule-wechat',
    name: 'goldRuleWechat',
    component: () => import(/* webpackChunkName: 'goldRule' */ '@/views/goldStore/goldRuleInWechat.vue')
  },
  {
    path: '/gold-transfer',
    name: 'goldRule',
    component: () => import(/* webpackChunkName: 'goldRule' */ '@/views/goldStore/goldTransfer.vue')
  },
  {
    path: '/gold-record',
    name: 'goldRecord',
    component: () => import(/* webpackChunkName: 'goldRecord' */ '@/views/goldStore/goldRecord.vue')
  },
  {
    path: '/my-gold',
    name: 'myGold',
    component: () => import(/* webpackChunkName: 'myGold' */ '@/views/goldStore/myGold.vue')
  },
  {
    path: '/Active/',
    component: Dash,
    children: [
      {
        path: 'homeCombination',
        component: () => import(/* webpackChunkName: 'homeCombination' */ '@/views/homeActivity/homeCombination.vue')
      },
      {
        path: 'arbitraryPurchase',
        component: () => import(/* webpackChunkName: 'arbitraryPurchase' */ '@/views/homeActivity/arbitraryPurchase.vue')
      },
      {
        path: 'homeActivity',
        component: () => import(/* webpackChunkName: 'homeActivity' */ '@/views/homeActivity/homeActivity.vue')
      },

      // {
      //   path: 'emptyPage',
      //   component: () => import(/* webpackChunkName: 'emptyPage' */ '@/views/homeActivity/emptyPage.vue')
      // },

      {
        path: 'shareGift',
        component: () => import(/* webpackChunkName: 'shareGift' */ '@/views/homeActivity/shareGift.vue')
      },

      // 分享记录明细
      {
        path: 'shareRecord',
        component: () => import(/* webpackChunkName: 'shareGift' */ '@/views/homeActivity/shareRecord.vue')
      },
      {

        // 分享领取页（注册）
        path: 'getSharedGift',
        component: () => import(/* webpackChunkName: 'shareGift' */ '@/views/homeActivity/getSharedGift.vue')
      },
      {

        // 分享领取页（注册成功）
        path: 'getSharedGiftSuccess',
        component: () => import(/* webpackChunkName: 'shareGift' */ '@/views/homeActivity/getSharedGiftSuccess.vue')
      },
      {
        path: 'newcomerTask',
        component: () => import(/* webpackChunkName: 'newcomerTask' */ '@/views/newcomerTask/newcomerTask.vue')
      },
    ]
  },
  // TODO: 非常老的页面了，暂时注释掉，可以考虑下个迭代移除
  // {
  //   path: '/lessonGuide',
  //   name: 'lessonGuide',
  //   component: () => import(/* webpackChunkName: 'lessonGuide' */ '@/views/lessonGuide/index.vue')
  // },
  {
    path: '/newLessonGuide',
    name: 'newLessonGuide',
    component: () => import(/* webpackChunkName: 'lessonGuide' */ '@/views/lessonGuide/newLessonGuideIndex.vue')
  },
  {
    path: '/newLessonGuide99',
    name: 'newLessonGuide99',
    component: () => import(/* webpackChunkName: 'lessonGuide' */ '@/views/lessonGuide/newLessonGuide99Index.vue')
  },
  {
    path: '/matters',
    name: 'matters',
    component: () => import(/* webpackChunkName: 'matters' */ '@/views/lessonGuide/matters.vue')
  },
  {
    path: '/bannerReview',
    name: 'BannerReview',
    component: () => import(/* webpackChunkName: 'bannerReview' */ '@/views/bannerReview/index.vue')
  },

  // FIXME: 已经废弃，待移除。为了保障以前分享的页面依然可以用
  // {
  //   path: '/freePlay',
  //   name: 'freePlay',
  //   component: () => import(/* webpackChunkName: 'freePlay' */ '@/views/radio/freePlay.vue')
  // },

  // FIXME: 已经废弃，待移除。为了保障以前分享的页面依然可以用
  {
    path: '/anticipate',
    name: 'anticipate',
    redirect: to => {
      const { query } = to;
      return {
        path: '/h5Share/section',
        query
      };
    }
    // component: () => import(/* webpackChunkName: 'robToRead' */ '@/views/radio/robToRead.vue')
  },

  // FIXME: 已经废弃，待移除。为了保障以前分享的页面依然可以用
  // {
  //   path: '/robToReadPlayBook',
  //   name: 'robToReadPlayBook',
  //   component: () => import(/* webpackChunkName: 'robToReadPlayBook' */ '@/views/radio/robToReadPlayBook.vue')
  // },

  // FIXME: 已经废弃，待移除。为了保障以前分享的页面依然可以用
  // {
  //   path: '/robToReadPlayCourse',
  //   name: 'robToReadPlayCourse',
  //   component: () => import(/* webpackChunkName: 'robToReadPlayCourse' */ '@/views/radio/robToReadPlayCourse.vue')
  // },
  {
    path: '/recommend',
    name: 'recommend',
    component: () => import(/* webpackChunkName: 'recommend' */ '@/views/download/index.vue')
  },
  {
    path: '/draft',
    name: 'draft',
    redirect: to => {
      location.replace(`${location.origin}/webapp${to.fullPath}`);
    }
  },
  {
    path: '/serviceAgree',
    name: 'serviceAgree',
    component: () => import(/* webpackChunkName: 'serviceAgree' */ '@/views/character/serviceAgree.vue')
  },
  {
    path: '/comming',
    name: 'comming',
    component: () => import(/* webpackChunkName: 'comming' */ '@/views/home/comming.vue')
  },
  //  新分享流程优化
  {
    path: '/h5share/',
    component: Dash,
    children: [
      {
        path: 'section',
        component: () => import(/* webpackChunkName: 'section' */ '@/views/h5Share/section.vue')
      },
      {
        path: 'sectionB',
        component: () => import(/* webpackChunkName: 'section' */ '@/views/h5Share/sectionB.vue')
      },
      {
        path: 'sectionBSuccess',
        component: () => import(/* webpackChunkName: 'section' */ '@/views/h5Share/sectionBSuccess.vue')
      },
      {
        path: 'waitingActivity',
        component: () => import(/* webpackChunkName: 'section' */ '@/views/h5Share/waitingActivity.vue')
      },
      {
        path: 'robToRead',
        component: () => import(/* webpackChunkName: 'section' */ '@/views/h5Share/section.vue')
      },
      {
        path: 'robToReadDetail',
        component: () => import(/* webpackChunkName: 'section' */ '@/views/h5Share/section.vue')
      },
      {
        path: 'robToReadDetailB',
        component: () => import(/* webpackChunkName: 'section' */ '@/views/h5Share/sectionB.vue')
      },

      // 免费音频课程分享
      {
        path: 'freeAudioLesson',
        name: 'freeAudioLesson',
        component: () => import(/* webpackChunkName: 'freeAudioLesson' */ '@/views/freezone/freeAudioLesson.vue')
      },

      // 阅读计划-优惠券
      {
        path: 'readPlanCoupon',
        name: 'readPlanCoupon',
        component: () => import(/* webpackChunkName: 'readPlanCoupon' */ '@/views/h5Share/readPlanCoupon.vue')
      },

      // 训练营售前页
      {
        path: 'trainingCamp',
        name: 'trainingCamp',
        component: () => import(/* webpackChunkName: 'trainingCamp' */ '@/views/h5Share/trainingCamp.vue')
      },

      // 训练营任务页
      {
        path: 'trainingTask',
        name: 'trainingTask',
        component: () => import(/* webpackChunkName: 'trainingTask' */ '@/views/h5Share/trainingTask.vue')
      },

      // 训练营阅读任务页
      {
        path: 'trainingReadTask',
        name: 'trainingReadTask',
        component: () => import(/* webpackChunkName: 'trainingReadTask' */ '@/views/h5Share/trainingReadTask.vue')
      },

      // 迁至运营中心后的新版活动页
      {
        path: 'activity',
        name: 'newOperateActivity',
        component: () => import(/* webpackChunkName: 'activity' */ '@/views/operateActivityV2') // operateActivity
      },
      {
        path: 'writeCamp',
        name: 'writeCamp',
        component: () => import(/* webpackChunkName: 'writeCamp' */ '@/views/h5Share/writeCamp')
      },

      // 阅读测评报告
      {
        path: 'readingReport',
        name: 'readingReport',
        component: () => import(/* webpackChunkName: 'readingReport' */ '@/views/h5Share/readingReport.vue')
      },

      // 阅读测评报告
      {
        path: 'jumpToApp',
        name: 'jumpToApp',
        component: () => import(/* webpackChunkName: 'jumpToApp' */ '@/views/h5Share/jumpToApp.vue')
      },

      // 编剧证分享页
      {
        path: 'screenWriteCertificate',
        name: 'screenWriteCertificate',
        component: () => import(/* webpackChunkName: 'jumpToApp' */ '@/views/h5Share/screenWriteCertificate')
      }
    ]
  },
  // 邀请优惠券
  {
    path: '/inviteCoupon/',
    component: Dash,
    children: [
      ...exchangeCourse,
      {
        path: 'friendHelp',
        name: 'friendHelp',
        component: () => import(/* webpackChunkName: 'inviteCoupon' */ '@/views/inviteCoupon/referral/friendHelp.vue')
      },
      {
        path: 'getCouponSuccess',
        name: 'getCouponSuccess',
        component: () => import(/* webpackChunkName: 'inviteCoupon' */ '@/views/inviteCoupon/referral/getCouponSuccess.vue')
      },
      {
        path: 'shareRecord',
        name: 'shareRecord',
        component: () => import(/* webpackChunkName: 'inviteCoupon' */ '@/views/inviteCoupon/shareRecord.vue')
      },
      {
        path: 'getSharedGift',
        name: 'getSharedGift',
        component: () => import(/* webpackChunkName: 'inviteCoupon' */ '@/views/inviteCoupon/getSharedGift.vue')
      },
      {
        path: 'getSharedGiftSuccess',
        name: 'getSharedGiftSuccess',
        component: () => import(/* webpackChunkName: 'inviteCoupon' */ '@/views/inviteCoupon/getSharedGiftSuccess.vue')
      },

      // {
      //   path: '/newBiePackage',
      //   name: 'newBiePackage2',
      //   component: () => import(/* webpackChunkName: 'inviteCoupon' */ '@/views/package/component.vue')
      // }
    ]
  },
  {
    path: '/wxlearn/',
    component: Dash,
    children: [
      {
        path: 'qrcode',
        component: () => import(/* webpackChunkName: 'wxlearn' */ '@/views/wxLearn/qrCode.vue')
      },
      {
        path: 'ifbeginreport',
        component: () => import(/* webpackChunkName: 'wxlearn' */ '@/views/wxLearn/ifBeginReport.vue')
      },
      {
        path: 'learnreport',
        component: () => import(/* webpackChunkName: 'wxlearn' */ '@/views/wxLearn/learnReport.vue')
      },
      {
        // 学习报告新增的一条路由，因为learnreport可能被举报了，用户打开会提示无法解析
        path: 'studyReport',
        component: () => import(/* webpackChunkName: 'wxlearn' */ '@/views/wxLearn/learnReport.vue')
      },
      {
        path: 'bindreg',
        component: () => import(/* webpackChunkName: 'wxlearn' */ '@/views/wxLearn/bindReg.vue')
      }
    ]
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: 'download' */ '@/views/download/index.vue'),
  },
  {
    path: '/fission',
    name: 'FissionActivity',
    component: Dash,
    children: [
      {
        path: 'course',
        name: 'FissionCourse',
        component: () => import(/* webpackChunkName: 'fissionActivity' */ '@/views/fission/fission')
      },
      {
        path: 'courseDetail',
        name: 'CourseDetail',
        component: () => import(/* webpackChunkName: 'fissionActivity' */ '@/views/fission/courseDetail')
      },
      {
        path: 'unlockDetail',
        name: 'UnlockDetail',
        component: () => import(/* webpackChunkName: 'fissionActivity' */ '@/views/fission/detail')
      }
    ]
  },
  {
    path: '/beforeSleepStory',
    name: 'beforeSleepStory',
    component: () => import(/* webpackChunkName: 'beforeSleepStory' */ '@/views/beforeSleepStory')
  },
  // 我的畅听券
  {
    path: '/myFreeTicket',
    name: 'myFreeTicket',
    component: () => import(/* webpackChunkName: 'myFreeTicket' */ '@/views/myFreeTicket')
  },
  // 国庆七日打卡活动
  {
    path: '/signinActivity',
    name: 'signinActivity',
    component: () => import(/* webpackChunkName: 'myFreeTicket' */ '@/views/signinActivity')
  },
  // 双十一组队答题start
  // 活动主页未组队
  {
    path: '/groupsAnswer',
    name: 'groupsAnswer',
    component: () => import(/* webpackChunkName: 'myFreeTicket' */ '@/views/groupsAnswer')
  },
  // 活动主页已组队
  {
    path: '/groupsAnswer/groupChallenge',
    name: 'groupChallenge',
    component: () => import(/* webpackChunkName: 'myFreeTicket' */ '@/views/groupsAnswer/groupChallenge')
  },
  // 奖品/排行页面
  {
    path: '/groupsAnswer/shopList',
    name: 'shopList',
    component: () => import(/* webpackChunkName: 'myFreeTicket' */ '@/views/groupsAnswer/shopList')
  },
  // 奖品详情
  {
    path: '/groupsAnswer/shopDetail',
    name: 'shopDetail',
    component: () => import(/* webpackChunkName: 'myFreeTicket' */ '@/views/groupsAnswer/shopDetail')
  },
  // 兑奖结果
  {
    path: '/groupsAnswer/shopResult',
    name: 'shopResult',
    component: () => import(/* webpackChunkName: 'myFreeTicket' */ '@/views/groupsAnswer/shopResult')
  },
  // 查看明细
  {
    path: '/groupsAnswer/detailList',
    name: 'detailList',
    component: () => import(/* webpackChunkName: 'myFreeTicket' */ '@/views/groupsAnswer/detailList')
  },
  // 活动落地页
  {
    path: '/groupsAnswer/receive',
    name: 'receive',
    component: () => import(/* webpackChunkName: 'myFreeTicket' */ '@/views/groupsAnswer/receive')
  },
  // 双十一组队答题end
  // 组队得勋章 start
  // 赛事首页
  {
    path: '/entityMedals/home',
    name: 'entityMedalsHome',
    component: () => import(/* webpackChunkName: 'entityMedals' */ '@/views/entityMedals/home')
  },
  // 赛事详情
  {
    path: '/entityMedals/detail',
    name: 'entityMedalsDetail',
    component: () => import(/* webpackChunkName: 'entityMedals' */ '@/views/entityMedals/detail')
  },
  // 训练营赛事详情
  {
    path: '/entityMedals/campDetail',
    name: 'entityMedalsCampDetail',
    component: () => import(/* webpackChunkName: 'entityMedals' */ '@/views/entityMedals/campDetail')
  },
  // 好友赛事
  {
    path: '/entityMedals/friendMedal',
    name: 'entityMedalsFriend',
    component: () => import(/* webpackChunkName: 'entityMedals' */ '@/views/entityMedals/friendMedal')
  },
  // 邀请加入
  {
    path: '/entityMedals/success',
    name: 'entityMedalsSuccess',
    component: () => import(/* webpackChunkName: 'entityMedals' */ '@/views/entityMedals/success')
  },
  // 组队得勋章 end
  // 激励勋章分享落地页
  {
    path: '/medalShare',
    name: 'medalShare',
    component: () => import(/* webpackChunkName: 'myFreeTicket' */ '@/views/medalShare')
  },
  // 会员卡start
  // 会员卡首页
  {
    path: '/membershipCard',
    name: 'membershipCard',
    component: () => import(/* webpackChunkName: 'membershipCard' */ '@/views/membershipCard/home')
  },
  // 兑课详情
  {
    path: '/membershipCard/exchangeDetail',
    name: 'exchangeDetail',
    component: () => import(/* webpackChunkName: 'membershipCard' */ '@/views/membershipCard/exchangeDetail')
  },
  // 兑课记录
  {
    path: '/membershipCard/exchangeRecord',
    name: 'exchangeRecord',
    component: () => import(/* webpackChunkName: 'membershipCard' */ '@/views/membershipCard/exchangeRecord')
  },
  {
    path: '/membershipCard/weeklyNewCourse',
    name: 'weeklyNewCourse',
    component: () => import(/* webpackChunkName: 'membershipCard' */ '@/views/membershipCard/weeklyNewCourse')
  },
  // 会员排行榜更多
  {
    path: '/memberRankingList',
    name: 'memberRankingList',
    component: () => import(/* webpackChunkName: 'membershipCard' */ '@/views/membershipCard/rankingList')
  },
  // 会员卡人群触达中转页
  {
    path: '/memberCrowdTouchPage',
    name: 'memberCrowdTouchPage',
    component: () => import(/* webpackChunkName: 'membershipCard' */ '@/views/membershipCard/crowdTouchPage')

  },
  // 会员卡end
  // 熊猫每日一题
  {
    path: '/pandaDailyQuestion',
    name: 'pandaDailyQuestion',
    component: () => import(/* webpackChunkName: 'pandaDailyQuestion' */ '@/views/pandaDailyQuestion')
  },
  // 熊猫脑力pk赛
  {
    path: '/pandaPKMatch',
    name: 'pandaPKMatch',
    component: () => import(/* webpackChunkName: 'pandaPKMatch' */ '@/views/pandaPKMatch')
  },
  // 邀请好友一起养熊猫
  {
    path: '/inviteForFood',
    name: 'inviteForFood',
    component: () => import(/* webpackChunkName: 'pandaDailyQuestion' */ '@/views/inviteForFood')
  },
  // app倾听者路由
  {
    path: '/miniListener/',
    name: 'miniListener',
    component: Dash,
    children: [
      {
        path: 'wxOpen',
        name: 'wxOpen',
        component: () => import(/* webpackChunkName: 'fissionActivity' */ '@/views/miniListener/wxOpen')
      },
      {
        path: 'listenerAuth',
        name: 'listenerAuth',
        component: () => import(/* webpackChunkName: 'fissionActivity' */ '@/views/miniListener/listenerAuth')
      },
      {
        path: 'listenerAuthSuccess',
        name: 'listenerAuthSuccess',
        component: () => import(/* webpackChunkName: 'fissionActivity' */ '@/views/miniListener/listenerAuthSuccess')
      },
    ]
  },
  // 第三方通用扫码登录
  {
    path: '/scanLogin/',
    name: 'scanLogin',
    component: Dash,
    children: [
      {
        path: 'wxOpen',
        name: 'wxOpen',
        component: () => import(/* webpackChunkName: 'fissionActivity' */ '@/views/scanLogin/wxOpen')
      },
      {
        path: 'auth',
        name: 'auth',
        component: () => import(/* webpackChunkName: 'fissionActivity' */ '@/views/scanLogin/auth')
      },
      {
        path: 'authSuccess',
        name: 'authSuccess',
        component: () => import(/* webpackChunkName: 'fissionActivity' */ '@/views/scanLogin/authSuccess')
      },
    ]
  },
  // 心理书答题拉新
  {
    path: '/psychologyBook',
    name: 'psychologyBook',
    component: () => import(/* webpackChunkName: 'psychologyBook' */ '@/views/psychologyBook')
  },
  // AI趣味挑战赛
  {
    path: '/AIChallenge',
    name: 'AIChallenge',
    component: () => import(/* webpackChunkName: 'AIChallenge' */ '@/views/AIChallenge')
  },
  // 科幻活动
  {
    path: '/fictionActivity',
    name: 'fictionActivity',
    component: () => import(/* webpackChunkName: 'psychologyBook' */ '@/views/fictionActivity')
  },
  {
    path: '/fictionActivityDraw',
    name: 'fictionActivityDraw',
    component: () => import(/* webpackChunkName: 'psychologyBook' */ '@/views/fictionActivity/draw')
  }
];

export default routes;
