/**
 * @file Vue 扩展
 * @author qiaogang
 * @date 2019-08-26
 */
import Vue from 'vue';
import { vueExtends } from '@igetcool/base-components';

Vue.use(vueExtends);

import './filters/truncate';
import './filters/getYMDStage';
import './filters/getChineseYMDStage';
import './filters/date2date';
import './filters/roundFloat';
