/**
 * 任务模块路由
 */
import Dash from '@/views/Dash';

const missionRoutes = {
  path: '/mission',
  name: 'missionModules',
  component: Dash,
  children: [
    {
      path: 'gameMission',
      name: 'gameMission',
      component: () => import(/* webpackChunkName: 'gameMission' */ '@/views/games/gameMission/index.vue')
    },
    {
      path: 'newSignTask',
      name: 'newSignTask',
      component: () =>
        import(/* webpackChunkName: 'newSignTask' */ '@/views/newSignTask/newSignTask.vue'),
    },
    {
      path: 'luckDraw',
      name: 'luckDraw',
      component: () => import(/* webpackChunkName: 'luckDraw' */ '@/views/games/luckDraw/index.vue')
    },
    {
      path: 'luckDrawVip',
      name: 'luckDrawVip',
      component: () => import(/* webpackChunkName: 'luckDraw' */ '@/views/games/luckDrawVip/index.vue')
    },
  ]
};

export default missionRoutes;
