import Vue from 'vue';

/**
 * 字符串截取过滤器，其中 options 参数请参考 lodash 的 truncate 函数
 * @param {string} str
 * @param {options} options
 * @options {number=30} options.length: The maximum string length.
 * @options {string='...'} options.omission: The string to indicate text is omitted.
 * @options {RegExp|string} options.separator: The separator pattern to truncate to.
 */
Vue.filter('date2date', (str, mode, liner = '-') => {
  // console.warn(str)
  if (typeof str !== 'string') { return }
  switch (mode) {
    case 'YM':
      return str.replace(/-/g, liner).slice(0, 8);
    case 'MD':
      return str.replace(/-/g, liner).slice(5);
    case 'YMD':
      return str.replace(/-/g, liner);
    default:
      break;
  }
});
