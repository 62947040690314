export const fixed = {

  // inserted 被绑定元素插入父节点时调用
  inserted() {
    let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    document.body.style.cssText += 'position:fixed;width:100%;top:-' + scrollTop + 'px;';
  },

  // unbind 指令与元素解绑时调用
  unbind() {
    let body = document.body;
    body.style.position = '';
    let top = body.style.top;
    document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top);
    body.style.top = '';
  }
};
