export const envPro = process.env.VUE_APP_ENVIRONMENT == 'PRODUCTION' || false;
export const testAppId = 'wx52c85dc2fd62faa7';// 少年得到测试


export const Config = {
  // 正式，本地用正式测试
  IOS_APK: process.env.VUE_APP_IOS_APK,
  ANDROID_APK: process.env.VUE_APP_ANDROID_APK,
  APP_ID: process.env.VUE_APP_APP_ID, // 正式，本地用正式测试
  WeChatQQType: process.env.VUE_APP_WX_QQ_TYPE,
  PASSGO_SERVER: process.env.VUE_APP_PASSGO_SERVER,
  WX_QRCODE: process.env.VUE_APP_WX_QRCODE,

  // 小程序 ID
  WX_MINI_APP_ID: process.env.VUE_APP_WX_MINI_APP,

  // 小程序原始 ID
  WX_MINI_ORIGIN_ID: process.env.VUE_APP_WX_MINI_ORIGIN_ID,
  SERVER: process.env.VUE_APP_SERVER,

  testAppId: 'wx52c85dc2fd62faa7', // 少年得到测试 公众号
  appId: envPro ? 'wx7dff126205e4b443' : testAppId, // 少年得到 公众号
  cnAppId: envPro ? 'wxa88d1212e559f22d' : testAppId, // 少年得到素养课 公众号
  zhAppId: envPro ? 'wx04ed6a5151db22f6' : testAppId, // 语文：泉灵的素养课家长号 公众号
  campAppId: envPro ? 'wx363214a9089aff00' : testAppId, // 训练营：少年读名著 公众号
};

// app原生路由
export const appRouter = {

  // 书房，老版本params_tab=1，新版本tab_name=book，无需兼容
  book: 'igetcool://juvenile.dedao.app/go/main?params_tab=1&tab_name=book',

  // 首页，老版本params_tab=0，新版本tab_name=discover，无需兼容
  home: 'igetcool://juvenile.dedao.app/go/main?params_tab=0&tab_name=discover',
  coupon: 'igetcool://juvenile.dedao.passport/passport/coupon'
};
