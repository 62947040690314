import Vue from 'vue';

/**
 * 字符串截取过滤器，其中 options 参数请参考 lodash 的 truncate 函数
 * @param {string} str
 * @param {options} options
 * @options {number=30} options.length: The maximum string length.
 * @options {string='...'} options.omission: The string to indicate text is omitted.
 * @options {RegExp|string} options.separator: The separator pattern to truncate to.
 */

function number2Str(num) {
  num = parseInt(num);
  return num < 10 ? '0' + num : '' + num;
}

Vue.filter('getYMDStage', (timeStr, type, liner1, liner2) => {
  timeStr = +timeStr;
  let date = new Date(timeStr);
  let year = date.getFullYear();
  let month = number2Str(date.getMonth() + 1);
  let day = number2Str(date.getDate());
  let hours = number2Str(date.getHours());
  let mins = number2Str(date.getMinutes());
  let seconds = number2Str(date.getSeconds());
  let result = '';
  switch (type) {
    case 'YM':
      result = year + liner1 + month;
      break;
    case 'MD':
      result = month + liner1 + day;
      break;
    case 'YMD':
      result = year + liner1 + month + liner1 + day;
      break;
    case 'HM':
      result = hours + liner2 + mins;
      break;
    case 'MS':
      result = mins + liner2 + seconds;
      break;
    case 'HMS':
      result = hours + liner2 + mins + liner2 + seconds;
      break;
    default:
      break;
  }
  return result;
});
