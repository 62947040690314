import Dash from '@/views/Dash.vue';
export default {
  path: '/trainingCamp/',
  component: Dash,
  children: [

    // 训练营进群引导，app内展示
    {
      path: 'groupTip',
      component: () =>
        // import(/* webpackChunkName: 'trainingCamp' */ '@/views/trainingCamp/groupTip.vue')
        import(/* webpackChunkName: 'trainingCamp' */ '@/views/trainingCamp/newGroupTip.vue')
    },
    // 训练营短信内打开的页面，用于加老师好友以及下载app
    {
      path: 'messageGroup',
      component: () =>
        import(/* webpackChunkName: 'trainingCamp' */ '@/views/trainingCamp/messageGroup.vue')
    },
    // 训练营短信内根据saleGroupId打开的页面，用于加老师好友以及下载app
    {
      path: 'smsQr',
      component: () =>
        import(/* webpackChunkName: 'trainingCamp' */ '@/views/trainingCamp/smsQr.vue')
    },
    // 训练营简版阅读测评报告
    {
      path: 'simpleReadingReport',
      component: () =>
        import(/* webpackChunkName: 'trainingCamp' */ '@/views/trainingCamp/simpleReadingReport.vue')
    },
    // 简版测评结果
    {
      path: 'simpleResult',
      component: () => import('@/views/trainingCamp/simpleResult.vue')
    }
  ]
};
