<template>
  <div class="operation-activity-login-container"
    v-if="show" v-fixed
    @click.self="cancelLogin">
    <transition name="slide-fade">
      <div class="login-form-wrap">
        <span class="igetcool cool-fanhui" v-if="ifInputCode" v-fastclick="modalBack"></span>
        <span class="igetcool cool-guanbi1" @click="cancelLogin"></span>
        <div class="bottom-content">
          <!-- 花瓶title没啥用 -->
          <div class="flower" v-if="false">
            <img src="./images/flower.png" alt="">
            <span>输入手机号获取99元新人专属礼包</span>
          </div>
          <!-- 输入框部分 -->
          <div class="input-wrap" v-if="!ifInputCode">
            <div class="left" v-fastclick="() => this.select.show = true">
              <span>+{{select.value}}</span>
              <span class="igetcool cool-sanjiao"></span>
            </div>
            <input class="right" type="tel" pattern="[0-9]*" ref="mobile"
              :maxlength="maxPhonelength"
              v-model="phone"
              @blur="inputDown"/>
          </div>
          <div class="send-code-btn" v-if="!ifInputCode" @click="()=>{sendCode()}">发送验证码
          </div>
          <p class="temp" v-if="ifInput"></p>
          <!-- 验证码部分 -->
          <div class="code-wrap" v-if="ifInputCode">
            <input class="code-input" type="tel" maxlength="4" v-model="code" ref="refeInput" @input="codeInputVal"
              @blur="inputDown">
            <div class="top">
              <span class="square" v-for="(item,index) in codeArr" :key="index"
                v-fastclick="($event)=>{activeInput($event,index)}">
                <span class="val" v-if="item.val!=''">{{item.val}}</span>
              </span>
            </div>
            <div class="bottom">
              <span class="left" v-if="false" @click="()=>{sendCode(1)}" v-log:click="{
              h5_type:'分享流程优化',
               page_name:'领取礼包',
              name: '发送语音验证码'
            }">使用语音验证</span>
              <span :class="`right ${ifStart?'disactive':''}`" v-fastclick="()=>{sendCode()}">{{ifStart?nowTime+'s':'获取验证码'}}</span>
            </div>
          </div>
          <!-- 无名输入框 -->
        </div>
      </div>
    </transition>
    <!--号码选择器 -->
    <Country-select
      :select="select"/>
  </div>
</template>

<script>

// import httpRequest from '@/utils/httpRequest';
import CountrySelect from '../CountrySelect/countrySelect';
import { validateMobile } from '@/utils/validate.js';
import { encryptRsa } from '@/utils/encryption';
import {
  getCodeRequest,
  // getVoiceCodeRequest,
  loginRegisterRequest
} from './serve';

export default {
  name: 'OperationActivityLogin',
  props: {
    show: Boolean,
    end: Function,
    from: {
      type: Object,
      default() {
        return {
          userChannel: 'share'
        };
      }
    }
  },
  data() {
    // console.log(this);
    // const {page, ifFromShare} = this.$route.query;
    return {
      btnText: '领取礼包',
      ifInput: true,
      ifInputCode: false,
      select: {
        show: false,
        value: 86
      },
      codeArr: [{ val: '' }, { val: '' }, { val: '' }, { val: '' }],
      code: '',
      phone: '',
      ifStart: false,
      fullTime: 60,
      nowTime: 60,
      timer: null,
      indecatorIndex: 0,
      ifReg: false,
      codeActive: false,
      sendCodeLock: false
    };
  },
  watch: {
    show(boo) {
      !boo && this.initData();
    }
  },
  computed: {
    maxPhonelength() {
      return this.select.value !== 86 ? 1000 : 11;
    }
  },
  methods: {
    cancelLogin() {
      this.closeHandle();
    },
    modalBack() {
      this.ifInputCode = false;
      this.ifStart = false;
      this.codeArr.map(item => {
        item.val = '';
        return item;
      });
    },

    codeInputVal(e) {
      this.code = this.code.replace(/[^0-9]/gi, '');
      this.codeArr.map(item => {
        item.val = '';
        return item;
      });
      if (this.code.length > 4) {
        this.code = this.code.slice(0, 4);
      }
      for (let key in this.code) {
        this.codeArr[key].val = this.code[key];
      }
      if (this.code.length === 4) {
        this._registerAjax();
      }
    },
    activeInput($e, index) {
      this.$refs.refeInput.focus();
    },
    sendCode(codeType) {
      if (this.sendCodeLock) { return }
      this._lockSendCode();
      this.code = '';
      this.codeArr.map(item => {
        item.val = '';
        return item;
      });
      let phoneStatus = validateMobile(this.phone, this.select.value);
      this.codeType = codeType;
      if (!phoneStatus) {
        this.$coolToast({
          text: '手机号码格式不正确',
          time: 3000
        });
        this._unLockSendCode();
        return;
      }
      if (this.ifStart) {
        this._unLockSendCode();
        return;
      }
      /**
       * @param {codeType}  1 语音验证码
       */
      this.sendCodeRequest(codeType);
    },
    _handleSendCode(res) {
      const errorMsgObj = {
        400011: '手机号已注册',
        10000: '发送成功'
      };
      const error = errorMsgObj[res.code] || res.msg || '网络错误';
      this.$coolToast({
        text: error,
        time: 3000
      });
    },
    _handleTimer() {
      clearTimeout(this.timer);
      this.nowTime = 60;
      this.ifStart = true;
      this.timer = setInterval(() => {
        this.nowTime = this.nowTime - 1;
        if (this.nowTime === 0) {
          this.ifStart = false;
          this.nowTime = this.fullTime;
          clearTimeout(this.timer);
        }
      }, 1000);
    },
    // 注册请求
    async _registerAjax() {
      if (this.ifReg) { return }
      this.ifReg = true;
      const params = this._getParams();
      try {
        const res = await loginRegisterRequest(params);
        if (res.code === 10000) {
          this.loginSuccess(res.data);
          this.closeHandle();
        } else {
          this.$coolToast({
            text: res.msg,
            time: 3000
          });
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.ifReg = false;
      }
    },
    _getParams() {
      this.$refs.refeInput.blur();
      this.code = this.codeArr.reduce((pre, next) => pre + next.val, '');
      let params = {
        phone: encryptRsa(this.phone),
        countryCode: encryptRsa(this.select.value || 86),
        smsCode: encryptRsa(this.code)
      };
      return params;
    },
    inputDown() {
      window.scrollTo(0, 0);
    },
    codeBlur() {
      window.scroll(0, 0);
    },
    async sendCodeRequest(type = 0) {
      const query = {
        phone: encryptRsa(this.phone),
        countrycode: encryptRsa(this.select.value)
      };
      let res = {};
      try {
        res = await getCodeRequest(query, type);
        if (res.code === 10000) {
          this.ifStart = true;
          this._handleTimer();
          this.ifInputCode = true;
        }
        this._handleSendCode(res);
      } catch (error) {

      } finally {
        this._unLockSendCode();
      }
    },
    initData() {
      this.ifInput = false;
      this.ifInputCode = false;
      this.ifStart = false;
      this.phone = '';
      this.codeArr.map(item => {
        item.val = '';
        return item;
      });
    },
    _lockSendCode() {
      this.sendCodeLock = true;
    },
    _unLockSendCode() {
      this.sendCodeLock = false;
    }
  },
  created() {},
  components: {
    CountrySelect
  }
};
</script>

<style lang="scss" scoped>
  .operation-activity-login-container {
    z-index: 999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: .6);

    .login-form-wrap {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 0.16rem;
      padding-top: 0.1rem;
      background-color: #fff;
      border-radius: 0.16rem 0.16rem 0 0;

      .cool-guanbi1 {
        font-size: 0.3rem;
        color: #a0a7b6;
        font-weight: bold;
        position: absolute;
        right: 0.15rem;
        top: 0.15rem;
      }

      .cool-fanhui {
        color: #989b9a;
        font-size: 0.3rem;
        /* color: #a0a7b6; */
        font-weight: bold;
        position: absolute;
        left: 0.15rem;
        top: 0.15rem;
      }

      .bottom-content {
        width: 2.91rem;
        margin: 0 auto;

        .input-wrap {
          width: 2.91rem;
          height: 0.5rem;
          margin: 0.3rem 0;
          display: flex;
          border-bottom: 1px solid #d7d6d6;

          .left {
            width: 0.6rem;
            display: flex;
            color: #ff5e2e;
            justify-content: center;
            align-items: center;
          }

          .right {
            flex: 1;
            border: none;
            outline: none;
            font-size: 0.18rem;
          }
        }

        .temp {
          height: .2rem;
          background-color: #FFFFFF;
        }

        .send-code-btn {
          width: 2.91rem;
          background-color: tomato;
          border-radius: 0.26rem;
          padding: 0.12rem 0;
          text-align: center;
          color: #fff;
        }

        .code-input {
          width: 100%;
          height: .1rem;
          margin-left: -100rem;
        }

        .code-wrap {
          .top {
            height: 0.6rem;
            display: flex;
            justify-content: space-between;
            margin-top: 0.35rem;
            position: relative;

            .square {
              width: 0.5rem;
              height: 0.6rem;
              border: 1px solid #e5e5e5;
              border-radius: 0.08rem;
              background-color: #fff;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;

              .indecator {
                width: 0.02rem;
                height: 0.2rem;
                background-color: #a0a7b6;
                animation: fade 1000ms infinite;
                -webkit-animation: fade 1000ms infinite;
              }

              .val {
              }
            }
          }

          .bottom {
            display: flex;
            justify-content: flex-end;
            margin-top: 0.17rem;

            span {
              font-size: 0.14rem;
              color: #898b94;
            }

            .disactive {
              color: #c6c7cb;
            }
          }
        }

        .flower {
          margin-top: 0.08rem;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 0.18rem;
            height: 0.18rem;
          }

          span {
            color: #ff5e2e;
            font-size: 0.14rem;
          }
        }
      }

      .title {
        color: #000000;
        font-size: 0.2rem;
        text-align: center;
        margin-top: 0.2rem;
      }
    }

    .package-btn {
      position: relative;
      height: 0.5rem;
      background-color: rgba(255, 95, 46, 1);
      box-shadow: 0px 4px 6px 0px rgba(255, 115, 73, 0.2);
      border-radius: 0.84rem;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.18rem;
      position: absolute;
      left: 0.16rem;
      right: 0.16rem;
      bottom: 0.2rem;
      z-index: 8;
    }

    .slide-fade-enter-active {
      transition: all 0.3s ease;
    }

    .slide-fade-leave-active {
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter, .slide-fade-leave-to
      /* .slide-fade-leave-active for below version 2.1.8 */
    {
      transform: translateY(100px);
      opacity: 0;
    }

    @keyframes fade {
      from {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      to {
        opacity: 1;
      }
    }

    @-webkit-keyframes fade {
      from {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      to {
        opacity: 1;
      }
    }
  }
</style>
