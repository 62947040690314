/**
 * @file VUE 过滤器扩展：toFixed 保留小数 例：.0 =? .00
 */
import Vue from 'vue';
import _ from '@/utils/lodash.custom.js';

/**
 * 小数四舍五入, 此处直接用的toFixed，只用于展示，最好不要参与计算
 * @param {number} value
 * @param {number} decimal
 * @param {boolean} float
 */
Vue.filter('roundFloat', (value, decimal = 2, float = true) => {
  if (!value) { return value }
  if (typeof decimal != 'number') {
    float = false;
  }
  try {
    if (typeof value != 'number') {
      value = parseFloat(value);
    }
    return float ? value.toFixed(decimal) : parseInt(value);
  } catch (error) {
  }
  return value;
});

