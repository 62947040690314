/**
 * 【转介绍】体验课打卡领实物 跳转朋友圈中转页
 */

const clockInTransfer = [
  {
    path: `/clockInTransfer`,
    name: '跳转朋友圈中转页',
    component: () => import('@/views/clockInTransfer/index')
  },
];

export default clockInTransfer;
