<template>
  <transition name="fade">
    <div class="country-select" v-show="select.show" @click="hide(false)">
      <transition name="slide">
        <div class="main" v-show="select.show" @click.stop>
          <div class="title">选择国际电话区号</div>
          <ul>
            <li v-for="(item,index) in countries" :key="index" @click="choose(item)">
              <div class="name">{{item.name}}</div>
              <div class="code">+{{item.codes}}</div>
              <div class="action">
                <i class="igetcool cool-duigou" v-if="item.codes == select.value"></i>
              </div>
            </li>
          </ul>
        </div>
     </transition>

    </div>
  </transition>
</template>
<script>
import countryCode from './countryCode.json';

export default {
  props: {
    select: {
      type: Object
    }
  },
  data() {
    return {
      countries: countryCode.list
    };
  },
  mounted() {
  },
  methods: {
    hide() {
      this.select.show = false;
      this.$emit('close');
    },
    choose(item) {
      this.select.value = item.codes;
      this.$emit('select', this.select);
      this.hide();
    }
  }
};
</script>
<style lang="scss" scoped>
.cool-duigou {
  color:#FF7349;
}
// 动画效果
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
.slide-enter-active, .slide-leave-active {
  transition: all .3s;
  transform: translateY(0);

}
.slide-enter, .slide-leave-to {
 transform: translateY(100%);
}
// 国际电话区号
.country-select{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .7);
  .main{
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding-left: 0.1rem;
    .title{
      padding:  0.1rem 0;
      color: #666;
      margin: 0;
      font-size: 0.15rem;
    }
  }
  ul {
    overflow: auto;
    width: 100%;
    height: 420px;
    -webkit-overflow-scrolling: touch;
    padding-right: 17px;
    li {
      padding: 0.1rem 0;
      color: #333;
      font-size: 0.15rem;
      border-top:solid rgba(0,0,0,.15) 1px;
      display: flex;
      align-items: center;
      .name{
        flex: 0 0 1rem;
        width: 1rem;
        font-weight: 500;
      }
      .code{
        flex: 0 0 1rem;
        width: 1rem;
      }
      .action{
        box-sizing: border-box;
        padding-right: 0.1rem;
        font-size: 0.15rem;
        flex: 1;
        text-align: right;
      }
    }
  }
}


</style>