<template>
  <router-view v-if="!app"></router-view>
  <div class="list" v-else>
    <div>路由目录：</div>
    <div v-for="(item, index) in routers" :key="`list-${index}`">
      <div v-if="!item.children">
        <a :href="item.path">{{ item.path }}</a>
      </div>
      <div v-else>
        <div v-for="(items, indexs) in item.children" :key="`children-${indexs}`">
          <a :href="item.path + items.path">{{ `${item.path}${items.path}` }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from './router';
import { autoLaunch, LogTrack, compareVersion, JS2Native } from '@igetcool/base-components';
import { env, href } from './utils';

export default {
  data: () => {
    return {
      routers: [],
      app: false
    };
  },
  methods: {
    sortBy(name) {
      return function(a, b) {
        let c = a[name];
        let d = b[name];
        if (c < d) { return -1 }
        else if (c > d) { return 1 }
        else { return 0 }
      };
    }
  },
  created() {
    // 埋点自动上报
    let routes = this.$router.options.routes;
    LogTrack.track({}, 'autoTrack', true, routes);
    this.$timeTrack('appCreated');
    // 开发环境渲染一下路由目录，只在根目录渲染
    const appEnv = process.env.VUE_APP_ENVIRONMENT;
    this.app = appEnv !== 'PRODUCTION' && window.location.pathname === '/';
    if (this.app) {
      let array = [
        ...router.options.routes
      ];
      this.routers = array.sort(this.sortBy('path'));
      this.dev = true;
    }
  },
  mounted() {
    this.$timeTrack('appMounted');
    autoLaunch.init();
    try {
      if (env.isApp) {
        const urlSearch = new URLSearchParams(location.search);
        const autoLaunch = urlSearch.get('autolaunch');
        const appOpen = urlSearch.get('appOpen');
        if (autoLaunch && appOpen) {
          let url = autoLaunch;
          if (env.isIOSApp) {
            url = decodeURIComponent(decodeURIComponent(url));
          }
          href(url);
        }
      }
      // app内关闭app原生的loading，只在app内生效，且版本大于6.16.0，且没有传入closeAppLoadingDisabled参数
      if (env.isApp && !this.$route.query.closeAppLoadingDisabled) {
        if (compareVersion(env.appVer, '6.16.0') >= 0) {
          JS2Native({
            name: 'closeAppLoading'
          });
        }
      }
    } catch (error) {
      // console.log(error)
    }
  }
};
</script>

<style scoped>
.list {
  font-size: 16px;
  padding: 18px;
}

a,
a:visited {
  color: blue !important;
}
</style>

<style>
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0;
}

html {
  height: 100% !important;
}

body {
  height: 100% !important;
  background-color: #fff;
}
.containers {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  min-width: 240px;
  max-width: 480px;
}

.container {
  margin-right: auto;
  margin-left: auto;
}

.containerRob {
  margin: 0 auto;
}

@media (min-width: 768px) {
  .containers {
    max-width: 750px;
  }

  .container {
    max-width: 750px;
  }

  .containerRob {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .containers {
    max-width: 970px;
  }

  .container {
    max-width: 970px;
  }

  .containerRob {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .containers {
    max-width: 1200px;
  }

  .container {
    max-width: 1000px;
  }

  .containerRob {
    max-width: 1000px;
  }
}

.containersComments {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  min-width: 200px;
  max-width: 480px;
}

@media (min-width: 768px) {
  .containers {
    max-width: 750px;
  }

  .containersMix {
    max-width: 750px;
  }

  .containersComments {
    max-width: 690px;
  }
}

@media (min-width: 992px) {
  .containers {
    max-width: 970px;
  }

  .containersMix {
    max-width: 750px;
  }

  .containersComments {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .containers {
    max-width: 1000px;
  }

  .containersMix {
    max-width: 750px;
  }

  .containersComments {
    max-width: 1000px;
    margin: 0 auto;
  }
}
</style>

