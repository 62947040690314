/**
 * 开发插件介绍：https://cn.vuejs.org/v2/guide/plugins.html
 */
import messageBoxVue from './messageBox.vue';

const MessageBox = {};
// vue的install方法，用于定义vue组件
MessageBox.install = function(Vue) {
  const MessageBoxInstance = Vue.extend(messageBoxVue);
  let currentMsg, instance;
  const initInstance = () => {
    // 实例化vue实例
    currentMsg = new MessageBoxInstance();
    let msgBoxEl = currentMsg.$mount().$el;
    document.body.appendChild(msgBoxEl);
  };
  // 在Vue的原型上添加实例方法，以全局调用
  Vue.prototype.$msgBox = {
    showMsgBox(options) {
      if (!instance) {
        initInstance();
      }
      if (typeof options === 'string') {
        currentMsg.messageWords = options;
      } else if (typeof options === 'object') {
        Object.assign(currentMsg, options);
      }
      return currentMsg.showMsgBox().then(() => {
        currentMsg = null;
        return Promise.resolve();
      });
    }
  };
};
export default MessageBox;