
import httpRequest from '@/utils/httpRequest';

const privew = '/app-api-user-server/white/';

/**
 * 获取验证码
 * @param {query}
 * @param {phone} 手机号
 * @param {countrycode}  手机区号
 */
export const getCodeRequest = (query, type) => {
  if (type) {
    return httpRequest.get(`${privew}sms/voice/v1.json`, {
      ...query,
      smstype: 0
    });
  }
  return httpRequest.get(`${privew}sms/smscode/v2.json`, {
    ...query,
    smstype: 0,
    type: 0
  }, {
    headers: {
      'App-Version': '3.0.0'
    }
  });
};

/**
 * 登录/注册
 * @param {phone} 手机号
 * @param {countrycode} 手机号区号
 * @param {smscode} 验证码
 * @param {putUrl} 投放地址
 * @param {userChannel} 投放渠道
 * @param {shareUserPid}
 * @param {h5Pid}
 */
const operatePrivew = '/growth-server/';
export const loginRegisterRequest = params => httpRequest.post(`${operatePrivew}user/codeLogin`, params);


