/**
 * @file 校验函数库，用于各种规则的校验
 * @author qiaogang
 * @date 2019-08-28
 */

/**
 * 简单的校验手机号码
 * @param {string} phone
 * @return {boolean}
 */
export function validateMobile(phone, countryCode = 86) {
  phone = String(phone);
  if (countryCode == 86) {

    // 简单判断国内手机号
    const regExp = /^1\d{10}$/;
    return regExp.test(phone);
  } else {
    return true;
  }
}
