/**
 * 【领读营】卖书领体验课相关路由
 */

const missionRoutes = [
  {
    path: `/intro-keys2`,
    name: '转介绍图书资源位领课',
    component: () => import('@/views/introduce/introduce-book/keys')
  },
  {
    path: '/intro2-success',
    name: '转介绍v2兑换成功',
    component: () => import('@/views/introduce/success')
  },
];

export default missionRoutes;
