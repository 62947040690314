/**
 * @file 获取分享信息
 * 仅获取分享信息，设置分享信息使用 webSDK 设置
 * @author qiaogang
 * @date 2019-11-07
 */


import { httpRequest } from './index';

// 默认分享信息
const defaultShareInfo = {

};

/**
 * 获取分享信息
 * @param {Object} options
 * @options {string} options.shareId 分享课程id
 * @options {string} options.shareType 分享课程类型
 */
export function fetchShareInfo(options) {
  const { shareId, shareType, url = '', platForm } = options;

  // DOC: http://wiki.luojilab.com/pages/viewpage.action?pageId=29608315
  const shareInfoUrl = '/app-api-common-server/white/share/config.json';
  return httpRequest.request(shareInfoUrl, {

    // 这里只为了获取课程分享信息，url是为了计算微信授权签名的，
    // 而现在授权签名和分享接口拆分，因此这里的 url 不用传具体值了。但是不传接口又会挂。
    url,
    shareRelaPid: shareId,

    // 0:听书详情页 1:听课详情页 2:推荐页 3:h5页面 分享活动页面 or 投票页
    // 6:分享音频-免费专区 7:分享音频-图书 8:分享音频-课程
    shareType,
    platForm
  }).then(res => {
    if (res.code === 10000) {
      return res.data;
    } else {
      return defaultShareInfo;
    }
  });
}
