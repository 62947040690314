import Vue from 'vue';
import OLogin from './OperationAcitvityLogin';



export default {
  install(Vue) {
    const OLoginConstructor = Vue.extend(OLogin);

    const createElement = () => {
      return new OLoginConstructor({
        el: document.createElement('div'),
      });
    };
    let instance = null;

    // const channel = new MessageChannel();
    // 在Vue的原型上添加实例方法，以全局调用
    Vue.prototype.$OLogin = {
      show() {
        if (!instance) {
          instance = new createElement();
          document.body.appendChild(instance.$el);
        }
        instance.show = true;
        instance.end = function() {};
        return new Promise((resolve) => {
          instance.loginSuccess = (data) => {
            resolve({ data, instance });
          };
          instance.closeHandle = () => {
            instance.show = false;
          };
        });

      },
      hide() {
        instance.show = false;
      },
    };
  }
};
