import Dash from '@/views/Dash';

const appRouter = [
  {

    // App - 个人中心 - 我的账户 - 绑定三类支付方式说明页
    path: '/pay/',
    component: Dash,
    children: [
      {
        path: 'microLetter',
        name: '微信支付',
        component: () => import(/* webpackChunkName: 'pay' */ '@/views/pay/microLetter.vue')
      },
      {
        path: 'bankCard',
        name: '银行卡支付',
        component: () => import(/* webpackChunkName: 'pay' */ '@/views/pay/bankCard.vue')
      },
      {
        path: 'payTreasure',
        name: '支付宝支付',
        component: () => import(/* webpackChunkName: 'pay' */ '@/views/pay/payTreasure.vue')
      }
    ]
  },
  {

    // App - 个人中心 - 兑换中心 - 兑换规则
    path: '/exchange',
    component: Dash,
    children: [
      {
        path: 'rule',
        name: 'exchangeRule',
        component: () => import(/* webpackChunkName: 'exchange' */ '@/views/exchange/rule.vue')
      }
    ]
  },
  {

    // App - 个人中心 - 设置 - 使用协议
    path: '/employ',
    name: 'employ',
    component: () => import(/* webpackChunkName: 'employ' */ '@/views/character/employ.vue')
  },
  {

    // App - 个人中心 - 设置 - 使用帮助
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: 'help' */ '@/views/character/help.vue')
  },
  {

    // App - 个人中心 - 设置 - 隐私协议
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: 'privacy' */ '@/views/character/privacy.vue')
  },
  {
    path: '/destroy',
    name: 'destroy',
    component: () => import(/* webpackChunkName: 'privacy' */ '@/views/character/destroy.vue')
  },
  {
    path: '/newBiePackage',
    name: 'newBiePackage',
    component: () => import(/* webpackChunkName: 'newBiePackage' */ '@/views/package/component.vue')
  },
  {
    path: '/projectionHelp',
    name: 'projectionHelp',
    component: () => import(/* webpackChunkName: 'projectionHelp' */ '@/views/character/projectionHelp.vue')
  },
  {
    // App - 【主战拉新】邀请好友得好礼
    path: '/inviteForGift',
    name: 'inviteForGift',
    component: () => import(/* webpackChunkName: 'inviteForGift' */ '@/views/inviteForGift/inviteForGift.vue')
  },
  {
    // App - 【主战拉新】邀请好友得好礼-邀请历史
    path: '/inviteHistory',
    name: 'inviteHistory',
    component: () => import(/* webpackChunkName: 'inviteHistory' */ '@/views/inviteForGift/inviteHistory.vue')
  },
  {
    // App - 【主战拉新】邀请好友得好礼-分享页
    path: '/inviteShare',
    name: 'inviteShare',
    component: () => import(/* webpackChunkName: 'inviteShare' */ '@/views/inviteForGift/inviteShare.vue')
  },
];

export default appRouter;
