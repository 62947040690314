import Vue from 'vue';
import Router from 'vue-router';
import appRouter from './appRouter';
import missionRoutes from './mission';
import routes from './router';
import trainingCamp from './trainingCamp';
import clockInTransfer from './clockInTransfer';
import intoduce from './intoduce';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    ...routes,
    ...appRouter,
    ...intoduce,
    ...clockInTransfer,
    missionRoutes,
    trainingCamp,
    {
      path: '/_/h5-login',
      name: 'h5LoginPage',
      component: () => import(/* webpackChunkName: 'h5LoginPage' */ '@/views/h5LoginView/index.vue')
    },
    {
      path: '/web/login',
      name: 'webLogin',
      component: () => import(/* webpackChunkName: 'h5LoginPage' */ '@/views/webLogin/index.vue')
    }
  ]
});
