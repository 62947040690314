import Vue from 'vue';
import App from './App.vue';
import router from './router';
import animated from 'animate.css';
Vue.use(animated);
import './commonImports';
import '@/assets/Oswald-Medium/Oswald-Medium.css';

import logTrackFactory from '@igc-lib/log-track';
// 拿到实例 并传入基础配置给工厂函数
/**
 * 参数：params:{基础配置:appid,上报的url 全局上报的函数名 公共参数}
 */
const logTrackInstance = logTrackFactory(
  process.env.VUE_APP_ENVIRONMENT == 'PRODUCTION' ? 'prod' : 'qa',
  {
    initConfig: {
      enable_native: false
    },
    enableOutAPP: false,
    // enableOutAPP: true,
    requestAutoTrackParams: {
      classKey: 'web_auto_track',
      itemKey: 'project_H5'
    },
  }
);
// 通过实例的init方法 在合适的地方进行初始化 一般为入口文件中
logTrackInstance.init();
// 将实例挂载到全局
// Vue.prototype.$logTrack = logTrackInstance;
Vue.prototype.$tracker = (evnetName, params) => {
  logTrackInstance.logTrack(evnetName, params);
};
new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
