import { env, compareVersion, JS2Native, Native2Js, webSDK, httpRequest, auth, Log, getAppUserPid, LogTrack, logTimeTrack } from '@igetcool/base-components';
import { fetchShareInfo } from './share';
import { Config } from '../config';

auth.config({
  appId: Config.APP_ID,
  passgoServer: Config.PASSGO_SERVER
});

function href(url) {
  if (/^igetcool:\/\//.test(url)) {
    if (env.isApp) {
      return JS2Native({
        name: 'navigateTo',
        params: {
          router: url,
        },
      });
    } else {
      webSDK.openApp(url);
      return Promise.resolve(0);
    }
  } else if (/^(http|https):\/\//.test(url)) {
    if (env.isApp) {
      return JS2Native({
        name: 'navigateTo',
        params: {
          router: `igetcool://juvenile.dedao.app/go/h5?params_url=${encodeURIComponent(url)}`,
        },
      });
    } else {
      window.location.href = url;
      return Promise.resolve(0);
    }
  } else {
    let content = '链接格式错误：' + url;
    if (env.isApp) {
      JS2Native({
        name: 'showToast',
        params: {
          content,
        },
      });
    } else {
      /* eslint-disable */
      console.warn(content);
    }
    return Promise.reject(1);
  }
}

export { env, compareVersion, JS2Native, Native2Js, webSDK, httpRequest, fetchShareInfo, auth, Log, href, getAppUserPid, LogTrack, logTimeTrack };
export * from '../config';

/**
 * 超长截取
 * @param str
 * @param len
 * @param tail
 */
export function overlong(str, len, tail = '...') {
  if (str == null || str.length === 0) {
    return '';
  }
  str = String(str);

  // 如果要截取长度为0的字符串, 那就直接返回空字符串.
  if (len === 0) {
    return '';
  }

  // 如果字符串长度小于要截取的长度, 返回字符串本身.
  if (str.length <= len) {
    return str;
  }

  // 如果结束字符为表情等特殊字符, 会占用4个字节
  // 此时要进行判断.
  let strEnd = len;
  if (str.codePointAt(len - 1) > 0xffff) {
    strEnd = len + 1;
  }
  return str.substring(0, strEnd) + tail;
}

export function formatDate(timestamp, fmt) {
  let date = new Date(timestamp);

  function padLeftZero(str) {
    return ('00' + str).substr(str.length);
  }

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

export const formatDuration = duration => {
  duration = Math.round(duration);
  let minutes = parseInt(duration / 60);
  let seconds = parseInt(duration % 60);
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return minutes + ':' + seconds;
};

/**
 * 将对象转为url的参数
 */
export const paramsToUrlSearch = params => {
  let searches = [];
  for (let key in params) {
    searches.push(key + '=' + params[key]);
  }
  return '?' + searches.join('&');
};

/**
 * 删除url中的某个参数
 */
export const getUrlRemoveParam = (url, name) => {
  let arr = url.split('?');

  if (arr.length > 1) {
    let queryString = arr[1];

    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
    queryString = queryString.replace(reg, '');

    let newUrl = arr[0] + '?' + queryString;
    return newUrl;
  }
  return url;
};

/**
 * 拼接跳转路径
 */
export function jumpTo(param) {
  let { url, data } = param;

  let arr = [];

  for (let key in data) {
    arr.push(key + '=' + encodeURIComponent(data[key]));
  }

  location.href = url + '?' + arr.join('&');
}
// 判断ipad
export function isIpad(){
  var ua = navigator.userAgent.toLowerCase();
	var s;
	s = ua.match(/iPad/i);

	if(s=="ipad")
	{
		return true;
	}
	else{
		return false;
	}
}
